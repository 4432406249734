import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

export default function Layout({children, theme}) {

    return (
        <div>
            
            <Helmet
                bodyAttributes={{ class: theme }}
            />

            <div class="container-fluid">
                <Link to="/">Accueil</Link>
                &nbsp;
                <Link to="/test-404">400000000004</Link>
                &nbsp;
                <Link to="/series">Séries</Link>
                <br />
                <br />
            </div>
            <div class="container-fluid">
                {children}
            </div>
            <br />
            <br />
        </div>
    )
}
